@use "../config" as *;
.waf-horizontal-list {
  padding-block: var(--space-8);
  margin-bottom: var(--space-8);
  .head-wrap {
    align-items: flex-end;
  }
  .title {
    margin-bottom: var(--space-3);
    @include title-with-icon(null, megaphone-fill, 20);
    &::before {
      color: var(--neutral-40);
    }
  }
}
.waf-multimedia-listing {
  padding-block: var(--space-12) var(--space-18);
  margin-inline: var(--space-2-neg);
  &.waf-listing {
    margin-top: 0;
  }
  &::before {
    content: "";
    height: 35.8rem;
    border-radius: 0 0 var(--border-radius-m) var(--border-radius-m);
    pointer-events: none;
    @include background(null, 'cssimages/pattern/black-gradient-bg-mobile.png', center / cover no-repeat);
    @extend %w-100;
    @extend %position-t-l;
    @extend %zindex-pattern;
  }
  .head-wrap {
    margin-bottom: var(--space-7);
    align-items: flex-end;
  }
  .title {
    @include title-with-icon(shape, play, 24, neutral-40);
  }
  .preview-swiper {
    @extend %mr-4-neg;
  }
  &.waf-listing .article-list {
    @include card-count(1.15, var(--space-6));
  }
}
@include mq(col-tablet) {
  .waf-horizontal-list {
    padding-block: var(--space-10);
    margin-bottom: var(--space-20);
    .title {
      margin-bottom: var(--space-2);
    }
  }
  .waf-listing.waf-horizontal-list .article-title {
    -webkit-line-clamp: 4;
    line-clamp: 4;
  }
  .waf-multimedia-listing {
    padding-block: 14.4rem 10.4rem;
    &::before {
      height: 41rem;
      border-radius: 0 0 var(--border-radius-xl) var(--border-radius-xl);
      @include bgImg("cssimages/pattern/black-gradient-bg-desktop.png");
    }
    &.waf-listing .article-list {
      @include card-count(4, var(--space-6));
    }
  }
}