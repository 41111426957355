@forward './main.scss';
@forward './swiper/swiper-bundle';
@forward './listing/listing-common';
@forward "./listing/arena-games-listing";
@forward './events/event-homepage-static';
@forward './module/static/';
@forward './module/static';
@forward './events/eventstrip';
@forward "./module/banner";
@forward "./module/common-hero";
@use "./config/" as *;
.waf-row-event {
    .swiper-button-next,
    .swiper-button-prev,
    .swiper-pagination {
        @extend %d-none;
    }
}
@include mq(col-md) {
    .waf-common-static {
        .card-head {
            padding-block: var(--space-2);
        }
    }
    .waf-common-static .card-item:has(.accordion-item) .card-label {
        margin-top: 0;
    }
    .waf-row-event {
        .swiper-button-next,
        .swiper-button-prev,
        .swiper-pagination {
            display: none;
        }
    }
}